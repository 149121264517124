import { Badge, Table, Tooltip } from '@mantine/core'
import {VideoInputOutputDTO} from '../../models/video-input-output-dto';
// import { TVehicleCameraList } from './types'

type TVideoBadge = {
  videoData: VideoInputOutputDTO
  cameraIndex: number
  color?:string
  zIndex?:number
  // lineWidth: number | null
  onClick: () => void
}

export function VideoBadge({
  cameraIndex,
  // lineWidth,
  videoData,
  zIndex,
  color,
  onClick
}: TVideoBadge) {

  // if (!lineWidth) return null
  const [hourVideoStart, minutesVideoStart] = videoData.inicioDoVideo.split(':')
  const videoStart = [hourVideoStart, minutesVideoStart].join(':')
  const [hourVideoEnd, minutesVideoEnd] = videoData.fimDoVideo.split(':')
  const videoEnd = [hourVideoEnd, minutesVideoEnd].join(':')

  return (
    <Table.Td pos="relative" pl={0} >
      <Tooltip label={`${videoStart} - ${videoEnd}`}>
        <Badge
          className="cursor-pointer xl:h-[1rem] sm:h-[0.5rem] "
          key={videoData.id}
          color={color||"darkBlue.9"}
          my="0rem"
          radius="sm"
          // size='xl'
          // h="1.0rem"
          variant="filled"
          px="4rem"
          pos="absolute"
          // left="0.1rem"
          top="-0.025rem"
          onClick={onClick}
          style={{
            zIndex:zIndex||0,
            marginLeft:"-0.5rem",
          }}
        >
        </Badge>
      </Tooltip>
    </Table.Td>
    // </Table.Tr>
  )
}
