import { Route, Routes } from 'react-router-dom'
import { PrivateRoutes } from './PrivateRoutes'
import { ROUTES_PATH } from './routesPath'
import { useAPIToken } from '@services/hooks'

export function Router() {
  useAPIToken()
  return (
    <Routes>
      <Route  path={ROUTES_PATH.vehicles.all} element={<PrivateRoutes />} />
      <Route  path={ROUTES_PATH.vehicles.cuiaba} element={<PrivateRoutes filter='cuiaba' />} />
      <Route  path={ROUTES_PATH.vehicles.campo_grande} element={<PrivateRoutes filter='campo_grande' />} />
      <Route  path={ROUTES_PATH.vehicles.corumba} element={<PrivateRoutes filter='corumba' />} />
    </Routes>
  )
}
