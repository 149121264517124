import { useEffect } from 'react'
import {
  useMsal,
  useIsAuthenticated,
  AuthenticatedTemplate
} from '@azure/msal-react'
import { Vehicles } from '@pages/vehicles'

type TPrivateRoutesProps = {
  filter?: string
}
export function PrivateRoutes({filter}:TPrivateRoutesProps) {
  const { instance, inProgress } = useMsal()
  const isAuthenticated = useIsAuthenticated()

  useEffect(() => {
    console.log(inProgress, isAuthenticated)
    if (inProgress === 'none' && !isAuthenticated) {
      instance.loginRedirect()
    }
  }, [instance, isAuthenticated, inProgress])

  return (
    <AuthenticatedTemplate>
      <Vehicles filter={filter} />
    </AuthenticatedTemplate>
  )
}

export default PrivateRoutes
