import {
  // FiGrid,
  // FiUser,
  // FiSettings,
  FiChevronRight,
  FiChevronLeft
} from 'react-icons/fi'
import { FaBuildingUser } from 'react-icons/fa6'
import { ActionIcon, Center, Loader, Stack, Text } from '@mantine/core'
import { Logo } from '@ui/Logo'
import { TNavbarLinks } from './types'
import { NavbarLink } from './navbar-link'
import { ROUTES_PATH } from '@routes/routesPath'
import { useLocation } from 'react-router-dom'
import { useState } from 'react'
import { twMerge } from 'tailwind-merge'
import { useLoader } from '../../context/loader-context'

const navbarLinks: TNavbarLinks[] = [
  // { label: 'Dashboard', icon: FiGrid },
  {
    label: 'Todas as Bases',
    icon: FaBuildingUser,
    link: ROUTES_PATH.vehicles.all
  },
  {
    label: 'Base Cuiabá',
    icon: FaBuildingUser,
    link: ROUTES_PATH.vehicles.cuiaba
  },
  {
    label: 'Base Campo Grande',
    icon: FaBuildingUser,
    link: ROUTES_PATH.vehicles.campo_grande
  },
  {
    label: 'Base Corumbá',
    icon: FaBuildingUser,
    link: ROUTES_PATH.vehicles.corumba
  }
]

export function Navbar() {
  const location = useLocation()
  const [isOpenedNavbar, setIsOpenedNavbar] = useState(true)
  const {spinnerShowing}=useLoader()

  return (
    <div>
      {spinnerShowing &&
        (<div className='flex justify-center items-center w-[100vw] h-[100vh] absolute bg-[#000000e0] z-[50000] overflow-hidden'>
          <Loader color="#f1f2f6" type="dots" size="xl" />
        </div>)}

      <nav
        className={twMerge(
          'flex flex-col w-30 h-full p-4 border-r border-gray-300 bg-zinc-100 transition-width duration-300 ease-in-out relative',
          isOpenedNavbar && 'w-[17rem]'
        )}
      >
        <ActionIcon
          pos="absolute"
          right={-12}
          top={70}
          variant="outline"
          color="darkBlue.9"
          radius="xl"
          size="sm"
          aria-label="abrir e fechar o menu"
          onClick={() => setIsOpenedNavbar(prevState => !prevState)}
        >
          {isOpenedNavbar ? (
            <FiChevronLeft style={{ width: '80%', height: '80%' }} />
          ) : (
            <FiChevronRight style={{ width: '80%', height: '80%' }} />
          )}
        </ActionIcon>
        <Center>
          <Logo />
          <Text
            className={twMerge(
              'hidden text-4xl uppercase font-semibold ml-3',
              isOpenedNavbar && 'flex'
            )}
          >
            DFu
            <Text
              component="span"
              c="lightBlue.4"
              className={twMerge('text-4xl uppercase font-semibold')}
            >
              2
            </Text>
            re
          </Text>
        </Center>

        <div className="flex-1 mt-8">
          <Stack justify="center" gap={0}>
            {navbarLinks.map(navbarLink => (
              <NavbarLink
                key={navbarLink.label}
                {...navbarLink}
                active={
                  navbarLink?.link
                    ? location.pathname === `/${navbarLink?.link}`
                    : null
                }
                isOpenedNavbar={isOpenedNavbar}
              />
            ))}
          </Stack>
        </div>

      </nav>
    </div>

  )
}
