import { Text, Tooltip, UnstyledButton } from '@mantine/core'
import { TNavbarLinks } from './types'
import { Link } from 'react-router-dom'
import { twMerge } from 'tailwind-merge'

type TNavbarLink = {
  active?: boolean | null
  isOpenedNavbar?: boolean
} & TNavbarLinks

export function NavbarLink({
  label,
  icon: Icon,
  link,
  active,
  isOpenedNavbar
}: TNavbarLink) {
  return (
    <Tooltip
      label={label}
      disabled={isOpenedNavbar}
      position="right"
      transitionProps={{ duration: 400 }}
    >
      <UnstyledButton
        component={Link}
        data-active={active || null}
        className={twMerge(
          'flex items-center justify-center w-12 h-12 mb-3 text-gray-800 rounded-md hover:bg-gray-200 data-[active]:bg-blue-100 data-[active]:text-slate-800',
          isOpenedNavbar && 'w-full justify-start px-6 gap-2'
        )}
        to={link || '#'}
      >
        <Icon size="1.25rem" />
        <Text
          className={twMerge(
            'transition-opacity duration-300 ease-in-out opacity-0 w-0',
            isOpenedNavbar && 'w-full opacity-1'
          )}
        >
          {label}
        </Text>
      </UnstyledButton>
    </Tooltip>
  )
}
